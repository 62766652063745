import {
  FullscreenOutlined,
  LeftCircleOutlined,
  LikeOutlined,
  RightOutlined,
  StopOutlined,
  UndoOutlined,
} from "@ant-design/icons";
import { Button, Card, Image, message, Spin, Tag, Tooltip } from "antd";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  approveTransferRequest,
  getPlayerRequestByRegisterNumber,
  getTransferById,
  resetTransferRequest,
  SERVER_BASE_URL,
  withHoldTransferRequest,
} from "../api/registrationDeskApis";
import { UserContext } from "../App";
import avatar from "../assets/avatar.png";
import noLogo from "../assets/noLogoTemplate.jpeg";
import { TRANSFER_STATUSES } from "../constants";
import { StyledModal, StyledModalDanger } from "./styledComponents/styledModal";

export const IndividualTransfer = () => {
  const navigate = useNavigate();
  const { transferId } = useParams();
  const [loading, setLoading] = useState(false);

  const { registrationDesk } = useContext(UserContext);

  const [player, setPlayer] = useState(null);
  const [transfer, setTransfer] = useState(null);

  const [approveModalOpen, setApproveModalOpen] = useState(false);
  const [withHoldModalOpen, setWithHoldModalOpen] = useState(false);
  const [resetModalOpen, setResetModalOpen] = useState(false);

  async function loadDetails() {
    setLoading(true);
    getTransferById(transferId)
      .then((res) => {
        const transferData = res.data.transfer;
        setTransfer(transferData);
        getPlayerRequestByRegisterNumber(transferData.playerRegistrationNumber)
          .then((res) => {
            setPlayer(res.data.request);
          })
          .catch((err) => {
            message.error("Something went wrong! Please try again.");
            console.error(err);
          });
      })
      .catch((err) => {
        message.error("Something went wrong! Please try again.");
        console.error(err);
      })
      .finally(() => setLoading(false));
  }

  const getTransferStatus = (status) => {
    const STATUSES = {
      0: "Transfer Pending",
      1: "Transfer On Hold",
      2: "Transfer Verified",
      3: "Transfer Completed",
    };

    const statusColors = {
      "Transfer Completed": "green",
      "Transfer Pending": "gray",
      "Transfer Verified": "blue",
      "Transfer On Hold": "red",
    };

    return <Tag color={statusColors[STATUSES[status]]}>{STATUSES[status]}</Tag>;
  };

  const getSignedDocumentLink = (link) => {
    const token = localStorage.getItem("token");
    return `${SERVER_BASE_URL}/registration-desk/get-player-document?token=${token}&key=${link}`;
  };

  const getActionBar = () => {
    switch (transfer.status) {
      case TRANSFER_STATUSES.approved:
        if (transfer.approvedBy === registrationDesk.role.toString()) {
          return (
            <Tooltip title="You have already taken your action on this request. Awaiting action from DIFA Registration Desk…">
              <Button type="primary" disabled>
                You have already operated on this request
              </Button>
            </Tooltip>
          );
        } else {
          return (
            <>
              <Button
                icon={<StopOutlined />}
                danger
                onClick={() => setWithHoldModalOpen(true)}
              >
                Withhold
              </Button>
              <Button
                icon={<LikeOutlined />}
                type="primary"
                disabled={loading}
                onClick={() => setApproveModalOpen(true)}
              >
                Approve
              </Button>
            </>
          );
        }
      case TRANSFER_STATUSES.fullyApproved:
        return (
          <Button type="primary" disabled>
            Transfer Completed
          </Button>
        );
      case TRANSFER_STATUSES.onHold:
        return (
          <Button
            icon={<UndoOutlined />}
            type="outlined"
            danger
            disabled={loading}
            onClick={() => setResetModalOpen(true)}
          >
            Reset Request
          </Button>
        );
      default:
        return (
          <>
            <Button
              icon={<StopOutlined />}
              danger
              onClick={() => setWithHoldModalOpen(true)}
            >
              Withhold
            </Button>
            <Button
              icon={<LikeOutlined />}
              type="primary"
              disabled={loading}
              onClick={() => setApproveModalOpen(true)}
            >
              Approve
            </Button>
          </>
        );
    }
  };

  function approveTransfer() {
    setLoading(true);
    approveTransferRequest(transferId)
      .then(() => {
        message.success("Transfer approved!");
        setApproveModalOpen(false);
        loadDetails();
      })
      .catch((err) => {
        message.error("Something went wrong! Please try again.");
        console.error(err);
      })
      .finally(() => setLoading(false));
  }

  async function withHoldTransfer() {
    setLoading(true);
    withHoldTransferRequest(transferId)
      .then(() => {
        message.success("Transfer has been with held!");
        setWithHoldModalOpen(false);
        loadDetails();
      })
      .catch((err) => {
        message.error("Something went wrong! Please try again.");
        console.error(err);
      })
      .finally(() => setLoading(false));
  }

  async function resetRequest() {
    if (!transfer || transfer.status !== TRANSFER_STATUSES.onHold) return;

    setLoading(true);
    resetTransferRequest(transfer.transferId)
      .then(() => {
        message.info("Transfer request has has been reset!");
        loadDetails();
        setResetModalOpen(false);
      })
      .catch((err) => {
        message.error("Something went wrong! Please try again.");
        console.error(err);
      })
      .finally(() => setLoading(false));
  }

  const withHoldModal = (
    <StyledModalDanger
      title="Confirm Action"
      visible={withHoldModalOpen}
      closable={false}
      destroyOnClose={true}
      cancelText="Cancel"
      okText="Confirm"
      okButtonProps={{ disabled: loading, type: "danger" }}
      onOk={withHoldTransfer}
      onCancel={() => setWithHoldModalOpen(false)}
    >
      Are you sure you want to withhold this transfer?
    </StyledModalDanger>
  );

  const approveModal = (
    <StyledModal
      title="Confirm Action"
      visible={approveModalOpen}
      closable={false}
      destroyOnClose={true}
      cancelText="Cancel"
      okText="Confirm"
      okButtonProps={{ disabled: loading }}
      onOk={approveTransfer}
      onCancel={() => setApproveModalOpen(false)}
    >
      Are you sure you want to approve this transfer?
    </StyledModal>
  );

  const resetModal = (
    <StyledModalDanger
      title="Confirm Action"
      visible={resetModalOpen}
      closable={false}
      destroyOnClose={true}
      cancelText="Cancel"
      okText="Confirm"
      okButtonProps={{ disabled: loading }}
      onOk={resetRequest}
      onCancel={() => setResetModalOpen(false)}
    >
      Are you sure you want to reset this transfer request?
    </StyledModalDanger>
  );

  useEffect(() => {
    loadDetails();
  }, []);

  return transfer && player ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        marginBottom: "20px",
      }}
    >
      {withHoldModal}
      {approveModal}
      {resetModal}
      <div style={{ width: "70%" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "left",
            gap: "1em",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "left",
              paddingBottom: "18px",
              flex: 0.01,
            }}
          >
            <Button
              icon={<LeftCircleOutlined />}
              style={{
                marginTop: "20px",
                border: "2px solid black",
                backgroundColor: "transparent",
                color: "black",
              }}
              onClick={() => {
                navigate("/transfer-requests", { replace: true });
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "left",
              flex: 0.99,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "left",
                flex: 0.99,
                alignItems: "center",
              }}
            >
              <h1
                style={{
                  fontSize: "large",
                  fontWeight: "bolder",
                  marginTop: "2%",
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              >
                TRANSFER DETAILS
              </h1>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                gap: "5px",
                alignItems: "center",
              }}
            >
              {getActionBar()}
            </div>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: "1em" }}>
          <Card>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "1em",
              }}
            >
              <Image
                src={
                  player.photo
                    ? SERVER_BASE_URL + `/get-player-image?key=${player.photo}`
                    : avatar
                }
                width={"20%"}
                style={{ borderRadius: "50%" }}
              />
              <h2>
                <b>{player.fullName}</b>
              </h2>
            </div>
            <div
              style={{
                marginTop: "10px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {getTransferStatus(transfer.status)}
            </div>
            <div
              style={{
                marginTop: "20px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                gap: "3em",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  minWidth: "200px",
                  alignItems: "center",
                }}
              >
                <img
                  src={
                    transfer.fromClubLogo
                      ? SERVER_BASE_URL +
                        `/get-club-logo?key=${transfer.fromClubLogo}`
                      : noLogo
                  }
                  alt="Club Logo"
                  style={{ maxWidth: "100px" }}
                />
                <b>{transfer.fromClubName}</b>
              </div>

              <RightOutlined />

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  minWidth: "200px",
                  alignItems: "center",
                }}
              >
                <img
                  src={
                    transfer.toClubLogo
                      ? SERVER_BASE_URL +
                        `/get-club-logo?key=${transfer.toClubLogo}`
                      : noLogo
                  }
                  alt="Club Logo"
                  style={{
                    maxWidth: "100px",
                  }}
                />
                <b>{transfer.toClubName}</b>
              </div>
            </div>

            <Card style={{ marginTop: "1em" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "auto",
                  alignItems: "center",
                }}
              >
                <div>Transfer Form</div>

                <div style={{ marginLeft: "auto" }}>
                  <a
                    className="link"
                    href={
                      transfer.transferDocument
                        ? getSignedDocumentLink(transfer.transferDocument)
                        : null
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button
                      type="primary"
                      icon={<FullscreenOutlined />}
                      disabled={loading || !transfer.transferDocument}
                    >
                      {transfer.transferDocument
                        ? "View"
                        : "No Transfer Document!"}
                    </Button>
                  </a>
                </div>
              </div>
            </Card>
          </Card>

          <Card title="Player Full Details">
            <div style={{ display: "flex", flexDirection: "row", gap: "1em" }}>
              <Card style={{ width: "50%" }}>
                <h4>Register Number</h4>
                <div>
                  <p>{player.registrationNumber}</p>
                </div>
              </Card>
              <Card style={{ width: "50%" }}>
                <h4>Player Name</h4>
                <div>
                  <p>{player.fullName}</p>
                </div>
              </Card>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "1em",
                paddingTop: "1em",
              }}
            >
              <Card style={{ width: "50%" }}>
                <h4>Email</h4>
                <div>
                  <p>{player.email || "-"}</p>
                </div>
              </Card>
              <Card style={{ width: "50%" }}>
                <h4>Mobile Number</h4>
                <div>
                  <p>{player.phone || "-"}</p>
                </div>
              </Card>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "1em",
                paddingTop: "1em",
              }}
            >
              <Card style={{ width: "50%" }}>
                <h4>Date Of Birth</h4>
                <div>
                  <p>{player.dateOfBirth || "-"}</p>
                </div>
              </Card>
              <Card style={{ width: "50%" }}>
                <h4>Blood Group</h4>
                <div>
                  <p>{player.bloodGroup || "-"}</p>
                </div>
              </Card>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "1em",
                paddingTop: "1em",
              }}
            >
              <Card title={"Home Address (India)"} style={{ width: "50%" }}>
                <div>
                  <p>{player.homeAddress || "-"}</p>
                </div>
              </Card>
              <Card title={"Saudi Address"} style={{ width: "50%" }}>
                <div>
                  <p>{player.saudiAddress || "-"}</p>
                </div>
              </Card>
            </div>
            <Card
              title="Company Name"
              style={{ width: "100%", marginTop: "1em" }}
            >
              <div>
                <p>{player.companyName || "-"}</p>
              </div>
            </Card>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "1em",
                paddingTop: "1em",
              }}
            >
              <Card style={{ width: "50%" }}>
                <h4>Iqama Number</h4>
                <div>
                  <p>{player.iqamaNumber || "-"}</p>
                </div>
              </Card>
              <Card style={{ width: "50%" }}>
                <h4>Passport Number</h4>
                <div>
                  <p>{player.passportNumber || "-"}</p>
                </div>
              </Card>
            </div>
            <Card
              title={"Player Documents"}
              style={{
                marginTop: "1em",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  gap: "1em",
                  justifyContent: "center",
                }}
              >
                <a
                  className="link"
                  href={
                    player.registrationForm
                      ? getSignedDocumentLink(player.registrationForm)
                      : null
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="button-link">
                    <p>
                      DIFA Registration Form
                      {!player.registrationForm && " - No File Uploaded"}
                    </p>
                    <FullscreenOutlined />
                  </button>
                </a>
                <a
                  className="link"
                  href={
                    player.affidavit
                      ? getSignedDocumentLink(player.affidavit)
                      : null
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="button-link">
                    <p>Affidavit{!player.affidavit && " - No File Uploaded"}</p>
                    <FullscreenOutlined />
                  </button>
                </a>
                <a
                  className="link"
                  href={
                    player.passport
                      ? getSignedDocumentLink(player.passport)
                      : null
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="button-link">
                    <p>Passport{!player.passport && " - No File Uploaded"}</p>
                    <FullscreenOutlined />
                  </button>
                </a>
                <a
                  className="link"
                  href={
                    player.iqama ? getSignedDocumentLink(player.iqama) : null
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="button-link">
                    <p>Iqama{!player.iqama && " - No File Uploaded"}</p>
                    <FullscreenOutlined />
                  </button>
                </a>
              </div>
            </Card>
          </Card>
        </div>
      </div>
    </div>
  ) : (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "80vh",
      }}
    >
      <Spin style={{ fontSize: "xx-large", color: "blue" }} />
    </div>
  );
};
